export default [
  {
    name: 'service_fee_manage',
    path: '/service_fee_manage',
    component: () =>
      import(
        /* webpackChunkName: "service_fee_manage" */ './ServiceFeeManage.vue'
      )
  }
]
