import axios from 'axios'
import { emitEvent } from '../util/event'
import { getGuid, getReqId } from '../util'
import { Message } from 'element-ui'
import dayjs from 'dayjs'
import { API_ENV } from '@/config'
import aes from './aes'
import { BaseURL, CBPBaseURL, client_id, client_secret } from '@/config'
const LOGOUT_EVENT = 'LOGOUT_EVENT'
let timeout = 600000
let getAuthDataFun
export function setGetAuthDataFun(fn) {
  getAuthDataFun = fn
}
let errorFlag = 0
export async function apiDataDecrypt({ res, showError, secret, rawData }) {
  if (res.data instanceof Blob) {
    return res.data
  }
  let data
  if (res.data && typeof res.data.data === 'string') {
    let dataStr = aes.decrypt(res.data.data, secret)
    try {
      data = JSON.parse(dataStr)
      if (API_ENV !== 'production') {
        console.log(data, res)
      }
      if ('header' in data) {
        if (
          data.header.returnCode !== '0' &&
          // 需要确认审批的结果 不需要报错
          !data.header.data?.aprvlId
        ) {
          let msg = data.header.errorMessages || []
          msg = msg[msg.length - 1] || {}
          msg = msg.message || msg.detailMessage
          if (showError) {
            Message.error(msg || 'Netwrok Error')
          }
        }
        return data
      }
      if ('code' in data) {
        if (data.code !== '0000' && data.code != '0') {
          let msg = data.msg || 'Netwrok Error'
          if (showError) {
            Message.error(msg)
          }
          // if (data.code === '401') {
          //   // 退出重新登录
          //   emitEvent(LOGOUT_EVENT, data)
          // }
          return rawData ? data : ''
        }

        if ((data.code === '0000' || data.code === '0') && !data.data) {
          data.data = {}
        }
        return rawData ? data : data.data
      }
      return data
    } catch (e) {
      console.warn('parse json response error', e)
    }
  }
  if (res.data?.code === '2') {
    emitEvent(LOGOUT_EVENT, { code: '401', msg: 'Login Status Expired' })
    // onLogout(expires_time! > Date.now() ? 'LoginFromOther' : 'Expired')
  }
  // gateway 系统维护中
  if (res.data?.code === '9999') {
    if (errorFlag > 0) return false
    errorFlag++
    Message.error({
      message: res.data.msg || 'System is maintaining',
      duration: 3000
    })
    setTimeout(() => {
      errorFlag = 0
    }, 3000)
  }
  return false
}

function dataStringTrim(obj = {}) {
  let objClone = Array.isArray(obj) ? [] : {}
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      objClone[key] = obj[key].trim()
    } else if (obj[key] && typeof obj[key] === 'object') {
      objClone[key] = dataStringTrim(obj[key])
    } else {
      objClone[key] = obj[key]
    }
  }
  return objClone
}

async function request({
  method,
  url,
  data,
  params,
  onUploadProgress,
  onDownloadProgress,
  responseType,
  // 返回原始 {code:x,data:xx,msg: xxx} 格式
  rawData = false,
  headers = {},
  showError = true
}) {
  let options = {
    url: url,
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers
    },
    params,
    onUploadProgress,
    onDownloadProgress,
    responseType: responseType,
    timeout: timeout
  }
  let access_token = null
  let secret = client_secret
  if (getAuthDataFun) {
    let [a, s] = await getAuthDataFun()
    access_token = a
    secret = s || client_secret
  }
  if (access_token) {
    options.headers['Authorization'] = 'Bearer ' + access_token
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('request data: ', data)
  }
  options.data = JSON.stringify({
    reqId: getReqId(),
    data: aes.encrypt(dataStringTrim(data) || {}, secret)
  })

  return axios(options)
    .then(res =>
      apiDataDecrypt({ res, showError, secret, reqData: data, rawData })
    )
    .catch(error => {
      console.log(error)
      let msg = error.message || 'Network timeout'
      if (error.request) {
        // 500 最高级提示
        if (error.request.status >= 500) {
          msg = 'System error'
        }
        // 登录超时
        if (error.request.status === 401) {
          emitEvent(LOGOUT_EVENT, { code: '401', msg: msg })
          return false
        }
      }
      if (showError) {
        Message.error(msg)
      }
      return false
    })
}
const cbbheader = {
  instCd: '205',
  userGrpCd: '02',
  tmZone: '',
  custRprsnId: '',
  deptId: '001',
  chnlDscd: '30',
  custId: '',
  staffId: ''
}
export function setCbbHeader(header = {}) {
  cbbheader.instCd = header.instCd || '205'
  cbbheader.deptId = header.deptId || '001'
  cbbheader.custId = header.custId || ''
  cbbheader.staffId = header.staffId || ''
  cbbheader.chnlDscd = header.chnlDscd || '30'
  cbbheader.userGrpCd = header.userGrpCd || '02'
  cbbheader.custRprsnId = header.custRprsnId || ''
}

export default {
  /**
   * 请求cbb接口
   * @param {*} srvcCd 接口code
   * @param {*} data  接口参数 （不含header）
   * @param {*} options  request api参数
   * @returns
   */
  requestCBB(srvcCd, data, options = {}) {
    let cbbData = {
      header: {},
      ...data
    }
    cbbData.header = {
      ...cbbheader,
      ...cbbData.header,
      srvcCd: srvcCd,
      lngCd: localStorage.lang || 'en',
      txDt: dayjs().format('YYYYMMDD'),
      guid: getGuid()
    }
    return request({
      method: 'post',
      url: CBPBaseURL,
      data: cbbData,
      ...options
    })
  },
  get(config) {
    let url = BaseURL + config.url
    return request({
      method: 'get',
      ...config,
      url
    })
  },
  post(config) {
    let url = BaseURL + config.url
    return request({
      method: 'post',
      ...config,
      url
    })
  },
  put(config) {
    let url = BaseURL + config.url
    return request({
      method: 'put',
      ...config,
      url
    })
  },
  patch(config) {
    let url = BaseURL + config.url
    return request({
      method: 'patch',
      ...config,
      url
    })
  },
  delete(config) {
    let url = BaseURL + config.url
    return request({
      method: 'delete',
      ...config,
      url
    })
  },
  request
}
