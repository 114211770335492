<i18n
  src="@/i18n/template/projects/teller/components/OperationAndTellerStaffSelect.json"
></i18n>
<template>
  <my-select
    :value="value"
    @input="$emit('input', $event)"
    :group="true"
    :filterable="true"
    :disabled="disabled"
    :clearable="clearable"
    :loading="loading"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    :options="options"
  >
  </my-select>
</template>
<script>
import api from '@/api/index'
import i18n from '@/i18n'
export async function getTellerStaffOptions() {
  let res = await api.requestCBB('SSF2000001', {
    StaffDeptInfoIn: {
      instCd: '205',
      actorNm: '',
      deptId: ''
    }
  })

  if (res.StaffInfoListOut) {
    let list = res.StaffInfoListOut.tbl || []
    return list.map(item => {
      return {
        value: item.staffId,
        label: `${item.actorNm}  (${i18n.t('StaffID')}: ${item.staffId})`
      }
    })
  }
  return []
}

export async function getOperationStaffOptions() {
  let res = await api.post({
    url: 'manager/user/queryUserList',
    data: {
      pageNum: 1,
      pageSize: 100
    }
  })

  if (res) {
    let list = res.list || []
    return list.map(item => {
      return {
        value: item.loginId,
        label: `${item.userNm}  (${i18n.t('StaffID')}: ${item.loginId})`
      }
    })
  }
  return []
}

export default {
  props: ['value', 'clearable', 'disabled'],
  data() {
    return {
      loading: true,
      options: []
    }
  },
  async created() {
    this.loading = true
    let res = await getOperationStaffOptions()
    let tellerRes = await getTellerStaffOptions()
    this.loading = false
    this.options = [
      {
        value: '01',
        getLabel: () => 'Teller',
        options: tellerRes
      },
      {
        value: '02',
        getLabel: () => 'Operation',
        options: res
      }
    ]
  }
}
</script>
