export const ButtonPermissionMap = {
  ClearCustomerDeviceApproval_Approval: ['OB0000001'],
  ClearCustomerDeviceApproval_Reject: ['OB0000002'],
  WelecomeMessage: ['OB0000003'],
  WelecomeMessage_AutoSending: ['OB0000004'],
  WelecomeMessage_NewWelecomeMessage: ['OB0000005'],
  WelecomeMessage_Edit: ['OB0000006'],
  WelecomeMessage_Remove: ['OB0000007'],
  DefaultReplyMessage: ['OB0000008'],
  DefaultReplyMessage_AutoSending: ['OB0000009'],
  DefaultReplyMessage_NewDefaultReply: ['OB0000010'],
  DefaultReplyMessage_Edit: ['OB0000011'],
  DefaultReplyMessage_Remove: ['OB0000012'],
  BusinessTypeConfiguration: ['OB0000013'],
  BusinessTypeConfiguration_AutoSending: ['OB0000014'],
  BusinessTypeConfiguration_NewBusinessType: ['OB0000015'],
  BusinessTypeConfiguration_Edit: ['OB0000016'],
  BusinessTypeConfiguration_Remove: ['OB0000017'],
  QuestionSetting: ['OB0000018'],
  QuestionSetting_AutoSending: ['OB0000019'],
  QuestionSetting_NewQuestion: ['OB0000020'],
  QuestionSetting_Edit: ['OB0000021'],
  QuestionSetting_Remove: ['OB0000022'],
  CustomerServiceSetting: ['OB0000023'],
  CustomerServiceSetting_Save: ['OB0000024'],
  CSScheduleManagement_Save: ['OB0000025'],
  CSScheduleManagement_Edit: ['OB0000026'],
  CSScheduleManagement_Export: ['OB0000027'],
  AddWorks: ['OB0000028'],
  EmojiManagement_Detail: ['OB0000029'],
  EmojiManagement_Online: ['OB0000030'],
  EmojiManagement_Offline: ['OB0000031'],
  EmojiManagement_Edit: ['OB0000032'],
  EmojiManagement_Delete: ['OB0000033'],
  RealNameRequiredFuncitons_Customer: ['OB0000034'],
  RealNameRequiredFuncitons_Merchant: ['OB0000035'],
  MemberLevelSetting_Edit: ['OB0000036'],
  MerchantManagement_Export: ['OB0000037'],
  MerchantManagement_Detail: ['OB0000038'],
  Merchant_DisableIMEnableIM: ['OB0000039'],
  Merchant_DisableUsersEnableUsers: ['OB0000040'],
  Merchant_BlockUsersEnableUsers: ['OB0000041'],
  Merchant_ModifyRegisterLoginID: ['OB0000042'],
  Merchant_ModifyRegisterMobile: ['OB0000043'],
  Merchant_BaseInfo: ['OB0000044'],
  Merchant_MerchantReport: 'OB0000319',
  BaseInfo_IdentityInformation_RegisterInformation_Edit: ['OB0000045'],
  BaseInfo_RegisterInformation_ScopeOfOperations: ['OB0000046'],
  MerchantBaseInfo_TransactionLimit_Edit: ['OB0000048'],
  BaseInfo_Introduction_Edit: ['OB0000049'],
  BaseInfo_FinancialInformation_Edit: ['OB0000050'],
  BaseInfo_SettlementInformation_Edit: ['OB0000051'],
  Merchant_AccountInfo: ['OB0000052'],
  AccountInfo_Block: ['OB0000053'],
  AccountInfo_Detail: ['OB0000054'],
  Merchant_AccountHistory: ['OB0000055'],
  MerchantAccountHistory_Export: ['OB0000056'],
  GoodsInfo: ['OB0000057'],
  GoodsInfo_Detail: ['OB0000058'],
  GoodsInfo_Prohibit: ['OB0000059'],
  Merchant_MyOrder: ['OB0000060'],
  MyOrder_Export: ['OB0000061'],
  // Merchant_PaymentHistory: ['OB0000062'],
  MerchantPaymentHistory_Export: ['OB0000063'],
  CashHistory: ['OB0000064'],
  CashHistory_Export: ['OB0000065'],
  Merchant_TrustyCoinsHistory: ['OB0000066'],
  MerchantTrustyCoinsHistory_Export: ['OB0000067'],
  RelationshipMgmt: ['OB0000068'],
  RelationshipMgmt_CustomerRelation_Remove: ['OB0000069'],
  RelationshipMgmt_EmployeeRelation_New: ['OB0000070'],
  RelationshipMgmt_EmployeeRelation_Delete: ['OB0000071'],
  MerchantComments: ['OB0000072'],
  MerchantException_Export: ['OB0000073'],
  GoodsManagement_AddProduct: ['OB0000074'],
  GoodsManagement_View: ['OB0000075'],
  GoodsManagementView_Edit: ['OB0000076'],
  GoodsManagementView_Offline: ['OB0000077'],
  GoodsManagementView_Delete: ['OB0000078'],
  GoodsManagement_AddProductSub: ['OB0000079'],
  GoodsManagement_Edit: ['OB0000080'],
  GoodsManagement_Offline: ['OB0000081'],
  GoodsManagement_Delete: ['OB0000082'],
  OrderManagement_Export: ['OB0000083'],
  MobileTopupPromotionRule_Save: ['OB0000084'],
  MobileTopupPromotionRule_AddProduct: ['OB0000085'],
  MobileTopupGoodsManagement_Detail: ['OB0000086'],
  MobileTopupGoodsManagement_Edit: ['OB0000087'],
  MobileTopupGoodsManagement_DisableEnable: ['OB0000088'],
  MobileTopupGoodsManagement_Delete: ['OB0000089'],
  NewCampagin: ['OB0000090'],
  CampaignManagement_Export: ['OB0000091'],
  CampaignManagement_Detail: ['OB0000092'],
  CampaignManagement_Offline: ['OB0000093'],
  CampaignManagement_Finished: ['OB0000094'],
  CampaignManagement_Statistics: ['OB0000095'],
  CampaignManagement_GoodsNumber: ['OB0000096'],
  CampaignManagement_UserCount: ['OB0000097'],
  CampaignManagement_Share: ['OB0000098'],
  CampaignGoodsManagement_View: ['OB0000099'],
  CampaignGoodsManagement_NewGoods: ['OB0000100'],
  CampaignGoodsManagement_Edit: ['OB0000101'],
  CampaignGoodsManagement_OnlineOffline: ['OB0000102'],
  CampaignGoodsManagement_Delete: ['OB0000103'],
  AddQuestionaire: ['OB0000104'],
  Questionaire_View: ['OB0000105'],
  Questionaire_Close: ['OB0000106'],
  Questionaire_Answer: ['OB0000107'],
  Answer_QnaireStatistics: ['OB0000108'],
  Answer_Export: ['OB0000109'],
  Answer_Detail: ['OB0000110'],
  Questionaire_Reward: ['OB0000111'],
  NewPromotion: ['OB0000112'],
  PromotionManagement_Export: ['OB0000113'],
  PromotionManagement_Applied: ['OB0000114'],
  PromotionManagement_Applied_Export: ['OB0000115'],
  PromotionManagement_Used: ['OB0000116'],
  PromotionManagement_Used_Export: ['OB0000117'],
  PromotionManagement_Detail: ['OB0000118'],
  PromotionManagement_Stop: ['OB0000119'],
  PromotionManagement_Notifications: 'OB0000311',
  PromotionManagement_Statistics: ['OB0000120'],
  CustomerManagement_Export: ['OB0000121'],
  CustomerManagement_Detail: ['OB0000122'],
  DisableIM_EnableIM: ['OB0000123'],
  DisableUsers_EnableUsers: ['OB0000124'],
  BlockUsers_EnableUsers: ['OB0000125'],
  Modify_Register_LoginID: ['OB0000126'],
  Modify_Register_RegisterMobile: ['OB0000127'],
  BaseInfo: ['OB0000128'],
  BaseInfo_IdentityInformation_Edit: ['OB0000129'],
  BaseInfo_TransactionLimit_Edit: ['OB0000130'],
  BaseInfo_ContactInformation_New: ['OB0000131'],
  BaseInfo_ContactInformation_Edit: ['OB0000132'],
  BaseInfo_ContactInformation_Delete: ['OB0000133'],
  AccountInfo: ['OB0000134'],
  AccountInfo_AccountDetails_Block: ['OB0000135'],
  AccountInfo_AccountDetails_Detail: ['OB0000136'],
  AccountInfo_POSDetails_PaymentHistory: ['OB0000137'],
  AccountHistory: ['OB0000138'],
  AccountHistory_Export: ['OB0000139'],
  MyOrder: ['OB0000140'],
  PaymentHistory: ['OB0000141'],
  PaymentHistory_Export: ['OB0000142'],
  TransferHistory: ['OB0000143'],
  TransferHistory_Export: ['OB0000144'],
  PointHistory: ['OB0000145'],
  PointHistory_Export: ['OB0000146'],
  CustomerDetail_CouponHistory: 'OB0000273',
  CustomerDetail_GoldHistory: 'OB0000375',
  CustomerDetail_SignHistory: 'OB0000317',
  CustomerDetail_SignHistory_export: 'OB0000318',
  CustomerDetail_CampaignHistory: 'OB0000312',
  CustomerDetail_CampaignHistory_export: 'OB0000313',
  CustomerDetail_QuestionaireHistory: 'OB0000314',
  CustomerDetail_QuestionaireHistory_export: 'OB0000315',
  CustomerDetail_MyFriends: 'OB0000316',
  CustomerDetail_MyBills: 'OB0000282',
  CustomerDetail_MyBills_Repayment: 'OB0000283',
  RedPacketHistory: ['OB0000147'],
  RedPacketHistory_Applied: ['OB0000148'],
  RedPacketHistory_Applied_Export: ['OB0000149'],
  TrustyCoinsHistory: ['OB0000150'],
  TrustyCoinsHistory_Export: ['OB0000151'],
  ReferralHistory: ['OB0000152'],
  ReportComplaints_Export: ['OB0000153'],
  ReportComplaints_Detail: ['OB0000154'],
  ReportComplaints_Detail_Detail: ['OB0000155'],
  ReportComplaints_Detail_Export: ['OB0000156'],
  ThirdPartyLoggingLog_Export: ['OB0000157'],
  CustomerException_Export: ['OB0000158'],
  SendMessage: ['OB0000159'],
  SendHistory: ['OB0000160'],
  TransactionManagement_Export: ['OB0000161'],
  FundSettlement_Export: ['OB0000162'],
  FundSettlement_Detail: ['OB0000163'],
  FundSettlement_Detail_Export: ['OB0000164'],
  BankTransfer_Save: ['OB0000165'],
  CashInOutForMerchant_save: 'OB0000327',
  GOLD_SALE_save: 'OB0000327',
  FX_Save: ['OB0000166'],
  MobileTopup_Save: ['OB0000167'],
  FixedDeposit_Save: ['OB0000168'],
  FixedWithdraw_Save: ['OB0000358'],
  LoanProduct_Save: ['OB0000169'],
  Realname_Modify_Save: ['OB0000170'],
  Paymentchannel_Save: ['OB0000171'],
  RoleManagement_Operation: ['OB0000172'],
  Operation_NewRole: ['OB0000173'],
  Operation_StaffNumber: ['OB0000174'],
  Operation_Detail: ['OB0000175'],
  Operation_Edit: ['OB0000176'],
  Operation_Delete: ['OB0000177'],
  Operation_Disable_Enable: ['OB0000178'],
  // MPortal_NewRole: ['OB0000179'],
  MPortal_StaffNumber: ['OB0000180'],
  MPortal_Detail: ['OB0000181'],
  MPortal_Edit: ['OB0000182'],
  MPortal_Delete: ['OB0000183'],
  MPortal_Disable_Enable: ['OB0000184'],
  Teller_NewRole: ['OB0000185'],
  Teller_StaffNumber: ['OB0000186'],
  Teller_Detail: ['OB0000187'],
  Teller_Edit: ['OB0000188'],
  Teller_Delete: ['OB0000189'],
  Teller_Disable_Enable: ['OB0000190'],
  Department_New: ['OB0000191'],
  Department_Edit: ['OB0000192'],
  Department_Delete: ['OB0000193'],
  NewStaff: ['OB0000194'],
  StaffManagement_Detail: ['OB0000195'],
  StaffManagement_Edit: ['OB0000196'],
  StaffManagement_Delete: ['OB0000197'],
  StaffManagement_ResetPassword: ['OB0000198'],
  StaffManagement_DisableEnable: ['OB0000199'],
  LiveRealTimemonitoring_Detail: ['OB0000200'],
  LiveRealTimemonitoring_Warn: ['OB0000201'],
  LiveRealTimemonitoring_Stop: ['OB0000202'],
  MyInfrormation: ['OB0000203'],
  ExportTask: ['OB0000204'],
  BillPaymentBussinessRulesSave: ['OB0000205'],
  MeterBillManagement_Import: ['OB0000206'],
  MeterBillManagement_Update: 'OB0000428',
  MeterBillManagement_ImportHistory: ['OB0000207'],
  MeterBillManagement_Detail: ['OB0000208'],
  MeterBillDetail_Export: 'OB0000210',
  MeterBillDetail_Detail: 'OB0000211',
  MeterBillDetail_Edit: 'OB0000244',
  MeterBillDetail_Close: 'OB0000245',
  BillSettlementManagementCreate: ['OB0000212'],
  BillSettlementManagementDetail: ['OB0000213'],
  BillSettlementManagementDownload: ['OB0000214'],
  BillSettlementManagementSettle: 'OB0000264',
  BillSettlementManagementDetail_Export: 'OB0000263',
  MonitoringDetail: ['OB0000215'],
  MonitoringDetailExport: ['OB0000216'],
  MonitoringEdit: ['OB0000217'],
  Customer360_ResetLoginPassword: ['OB0000218'],
  Customer360_ResetTransPassword: ['OB0000219'],
  Merchant360_ResetLoginPassword: ['OB0000220'],
  Merchant360_ResetTransPassword: ['OB0000221'],
  Customer360_LiveUnseal: ['OB0000222'],
  Merchant360_LiveUnseal: ['OB0000223'],
  LiveTimeManager_Save: ['OB0000224'],
  VillageBulkManage_Import: 'OB0000225',
  VillageBulkManage_Detail: 'OB0000226',
  VillageBulkManage_Delete: 'OB0000227',
  VillageBulkManage_D_Import: 'OB0000228',
  VillageBulkManage_D_Disburse: 'OB0000229',
  VillageDisburseManage_Export: 'OB0000230',
  VillageDisburseManage_FastDisburse: 'OB0000231',
  VillageDisburseManage_Disburse: 'OB0000232',
  VillageDisburseManage_BulkDetail: 'OB0000233',
  VillageRepaymentManage_Export: 'OB0000234',
  VillageRepaymentManage_Disurse: 'OB0000235',
  Customer360_ClearDevice: 'OB0000236',
  Customer360_LoginHistory: 'OB0000237',
  Customer360_BaseInfo_EmergencyContact_Edit: 'OB0000354',
  Merchant360_BaseInfo_EmergencyContact_Edit: 'OB0000355',
  Merchant360_ClearDevice: 'OB0000238',
  Merchant360_LoginHistory: 'OB0000239',
  Merchant360_PosManagement: 'OB0000246',
  Merchant360_PosManagement_Edit: 'OB0000247',
  Merchant360_StaffManagement: 'OB0000248',
  Merchant360_StaffManagement_Edit: 'OB0000249',
  Merchant360_StaffName: 'OB0000250',
  Merchant360_CouponHistory: 'OB0000251',
  Merchant360_CouponHistory_Export: 'OB0000252',
  Merchant360_CouponHistory_Detail: 'OB0000259',
  Merchant360_CouponHistory_Statistics: 'OB0000260',
  Merchant360_CouponHistory_Applied: 'OB0000261',
  Merchant360_CouponHistory_Used: 'OB0000262',
  Merchant360_LiveHistory: 'OB0000253',
  Merchant360_LiveHistory_Detail: 'OB0000255',
  Merchant360_LiveHistory_Delete: 'OB0000256',
  Customer360_LiveHistory: 'OB0000254',
  Customer360_LiveHistory_Detail: 'OB0000257',
  Customer360_LiveHistory_Delete: 'OB0000258',
  Customer360_AccountList_Block: 'OB0000270',
  Merchant360_AccountList_Block: 'OB0000271',
  Merchant360_TransactionSettlement: 'OB0000328',
  Merchant360_TransactionSettlement_Detail: 'OB0000329',
  Merchant360_TransactionSettlement_Export: 'OB0000330',
  Customer360_BlockingHistory: 'OB0000287',
  Customer360_BlockingHistory_Clear: 'OB0000289',
  Merchant360_BlockingHistory: 'OB0000290',
  Merchant360_BlockingHistory_Clear: 'OB0000292',
  InquireAccount_Block: 'OB0000272',
  ElectricityBillTownshipTotal_Export: 'OB0000240',
  ElectricityBillDailyReportByTownship_Export: 'OB0000241',
  ElectricityBillbyLastPaymentDueList_Export: 'OB0000242',
  ElectricityBillbyTownshipReceivingReport_Export: 'OB0000243',
  Merchant360_MembershipManagement: 'OB0000265',
  Merchant360_LiveHistory_Export: 'OB0000266',
  Customer360_LiveHistory_Export: 'OB0000267',
  Merchant360_MultilevelDept: 'OB0000268',
  Merchant360_Display: 'OB0000269',
  LoanBillManagement_Export: 'OB0000274',
  LoanBillManagement_RepaymentHistory: 'OB0000275',
  LoanBillManagement_Detail: 'OB0000276',
  LoanBillManagement_Repayment: 'OB0000277',
  EmeraldLoanSettlement_CreateSettlement: 'OB0000278',
  EmeraldLoanSettlement_Detail: 'OB0000279',
  EmeraldLoanSettlement_Download: 'OB0000280',
  EmeraldLoanSettlement_Settle: 'OB0000281',
  IndustryCategory_New: 'OB0000284',
  IndustryCategory_Edit: 'OB0000285',
  IndustryCategory_Delete: 'OB0000286',
  Customer360_Cancellation: 'OB0000293',
  Merchant360_Cancellation: 'OB0000294',
  LiveGift_New: 'OB0000295',
  LiveGift_Edit: 'OB0000297',
  LiveGift_Online: 'OB0000298',
  LiveGift_Offline: 'OB0000299',
  LiveGift_Delete: 'OB0000300',
  Banner_View: 'OB0000301',
  Banner_Detail: 'OB0000302',
  Banner_Show: 'OB0000303',
  Banner_Hide: 'OB0000304',
  BannerDetail_New: 'OB0000305',
  BannerDetail_Detail: 'OB0000306',
  BannerDetail_Edit: 'OB0000307',
  BannerDetail_Online: 'OB0000308',
  BannerDetail_Offline: 'OB0000309',
  BannerDetail_Delete: 'OB0000310',
  FixedDeposit_Edit: 'OB0000320',
  AdManage_New: 'OB0000321',
  AdManage_Edit: 'OB0000322',
  AdManage_Statistics: 'OB0000326',
  AdManage_Discontinue: 'OB0000323',
  AdManage_Delete: 'OB0000324',
  AdManage_Detail: 'OB0000325',
  RentalContractManagement_FastContract: 'OB0000338',
  RentalContractManagement_Download: 'OB0000339',
  RentalContractManagement_ImportContract: 'OB0000340',
  RentalContractManagement_ImportHistory: 'OB0000341',
  RentalContractManagement_Detail: 'OB0000342',
  RentalContractManagement_Edit: 'OB0000343',
  RentalContractManagement_Termination: 'OB0000344',
  RentalBillManagement_ImportWaterElectric: 'OB0000345',
  RentalBillManagement_ImportWaterElectricHistory: 'OB0000346',
  RentalBillManagement_Export: 'OB0000347',
  RentalBillManagement_Detail: 'OB0000348',
  RentalBillManagement_CashPayment: 'OB0000349',
  RentalSettlementManagement_CreateSettlement: 'OB0000350',
  RentalSettlementManagement_Detail: 'OB0000351',
  RentalSettlementManagement_Download: 'OB0000352',
  RentalSettlementManagement_Settle: 'OB0000353',
  BenefitManage_Switch: 'OB0000356',
  BenefitManage_Edit: 'OB0000357',
  RentalPaymentManage_Export: 'OB0000360',
  RentalPaymentManage_Detail: 'OB0000359',
  RentalPaymentManage_CashPayment: 'OB0000361',
  RentalBillManagement_Exempted: 'OB0000363',
  Customer360_JointAccountApplicationHistory: 'OB0000362',
  GoldManagementGoldBusinessRules_DailyLimit_Save: 'OB0000366',
  GoldManagementGoldBusinessRules_TrustyServiceFee_Save: 'OB0000367',
  GoldManagementGoldMarketPrice_Edit: 'OB0000368',
  GoldManagementGoldMarketPrice_ChangeHistory: 'OB0000369',
  GoldManagementPaymentManagement_Export: 'OB0000370',
  GoldManagementSettlementManagement_Detail: 'OB0000371',
  GoldManagementSettlementManagement_Download: 'OB0000372',
  GoldManagementSettlementManagement_Settle: 'OB0000373',
  AyeyarwaddyMonthReportByTwonship: 'OB0000376',
  AyeyarwaddyDailyReportByLedgerNo: 'OB0000377',
  AyeyarwaddyDailyReportByTownship: 'OB0000378',
  AyeyarwaddyMonthReportByTwonship_Export: 'OB0000379',
  AyeyarwaddyDailyReportByLedgerNo_Export: 'OB0000380',
  AyeyarwaddyDailyReportByTownship_Export: 'OB0000381',
  MandalayMonthReportByTownship: 'OB0000398',
  MandalayDailyReportByLastDate: 'OB0000382',
  MandalayDailyReportByLedgerNo: 'OB0000383',
  MandalayDailyReportByTownship: 'OB0000384',
  MandalayMonthReportByTwonship_Export: 'OB0000399',
  MandalayDailyReportByLastDate_Export: 'OB0000385',
  MandalayDailyReportByLedgerNo_Export: 'OB0000386',
  MandalayDailyReportByTownship_Export: 'OB0000387',
  NayPyiTawMonthReportByTwonship: 'OB0000388',
  NayPyiTawDailyReportByLedgerNo: 'OB0000389',
  NayPyiTawDailyReportByTownship: 'OB0000390',
  NayPyiTawMonthReportByTwonship_Export: 'OB0000391',
  NayPyiTawDailyReportByLedgerNo_Export: 'OB0000392',
  NayPyiTawDailyReportByTownship_Export: 'OB0000393',
  YangonMonthReportByTwonship: 'OB0000394',
  YangonDailyReportByLedgerNo: 'OB0000395',
  YangonMonthReportByTwonship_Export: 'OB0000396',
  YangonDailyReportByLedgerNo_Export: 'OB0000397',
  GameShopManagement_SyncProduct: 'OB0000400',
  GameShopManagement_Online: 'OB0000401',
  GameShopManagement_Offline: 'OB0000402',
  GameShopManagement_Apply: 'OB0000403',
  GameShopManagement_ApplyAndPublish: 'OB0000426',
  GameShopManagement_HotNonHot: 'OB0000404',
  GameShopManagement_Edit: 'OB0000405',
  GameShopManagement_RechargePackages_Online: 'OB0000406',
  GameShopManagement_RechargePackages_Offline: 'OB0000407',
  GameShopManagement_RechargePackages_Apply: 'OB0000408',
  GameShopManagement_RechargePackages_ApplyAndPublish: 'OB0000427',
  GameShopManagement_RechargePackages_Edit: 'OB0000409',
  GiftCardManagement_New: 'OB0000410',
  GiftCardManagement_Online: 'OB0000411',
  GiftCardManagement_Offline: 'OB0000412',
  GiftCardManagement_HotNonHot: 'OB0000413',
  GiftCardManagement_Delete: 'OB0000414',
  GiftCardManagement_Edit: 'OB0000415',
  GiftCardManagement_CardTypeSubCategory_Upload: 'OB0000416',
  GiftCardManagement_CardTypeSubCategory_New: 'OB0000417',
  GiftCardManagement_CardTypeSubCategory_Online: 'OB0000418',
  GiftCardManagement_CardTypeSubCategory_Offline: 'OB0000419',
  GiftCardManagement_CardTypeSubCategory_Delete: 'OB0000420',
  GiftCardManagement_CardTypeSubCategory_Edit: 'OB0000421',
  GiftCardSettlementManagement_GiftCard: 'OB0000422',
  GiftCardSettlementManagement_GiftCard_Settle: 'OB0000423',
  GiftCardSettlementManagement_GameShop: 'OB0000424',
  GiftCardSettlementManagement_GameShop_Settle: 'OB0000425',
  CustomerBlacklists_New: 'OB0000430',
  CustomerBlacklists_Edit: 'OB0000431',
  CustomerBlacklists_Remove: 'OB0000432',
  MerchantBlacklists_New: 'OB0000433',
  MerchantBlacklists_Edit: 'OB0000434',
  MerchantBlacklists_Remove: 'OB0000435',
  MobileNumberBlacklists_New: 'OB0000436',
  MobileNumberBlacklists_Edit: 'OB0000437',
  MobileNumberBlacklists_Remove: 'OB0000438',
  GameManagement_AddGame: 'OB0000450',
  GameManagement_Online: 'OB0000451',
  GameManagement_Edit: 'OB0000452',
  GameManagement_Delete: 'OB0000453'
}
