<i18n src="@/i18n/template/components/SearchBox.json"></i18n>
<template>
  <div class="search-box">
    <template v-for="item in config">
      <div v-if="item.hidden" :key="item.key" style="display: none"></div>
      <template v-else>
        <div
          v-if="item.type === 'tab-select'"
          :key="item.key"
          class="tab-select"
        >
          <el-badge
            :value="it.badgeText"
            :max="100"
            v-for="it in item.options"
            :key="it.value"
            :hidden="!!item.badgeText"
          >
            <el-button
              class="search-box-tab"
              :class="{ active: it.value === value[item.key] }"
              type="text"
              @click="onTabInput(it.value, item.key)"
            >
              {{ (it.getLabel && it.getLabel()) || it.label }}
              <span
                v-if="it.getSubLabel"
                class="search-box-tab-sub"
                :class="{ active: it.value === value[item.key] }"
              >
                {{ it.getSubLabel() }}
              </span>
            </el-button>
          </el-badge>
        </div>
        <div v-else class="search-box-item" :key="item.key">
          <p
            :class="{
              'search-box-label': true,
              required: item.required
            }"
            :style="item.labelStyle"
          >
            {{ (item.getLabel && item.getLabel()) || item.label }}
          </p>
          <my-select
            v-if="item.type === 'select'"
            :style="item.style"
            :value="value[item.key]"
            :group="item.group"
            @input="onInput($event, item.key)"
            :clearable="item.clearable !== undefined ? item.clearable : true"
            :multiple="item.multiple !== undefined ? item.multiple : false"
            :options="item.options"
            :disabled="item.disabled"
            :filterable="item.filterable"
            :getOptions="item.getOptions"
            :collapse-tags="
              item.collapseTags !== undefined ? item.collapseTags : false
            "
          >
          </my-select>
          <my-check-box
            v-else-if="item.type === 'checkBox'"
            :_fitWidth="180"
            :style="item.style"
            :value="value[item.key]"
            @input="onInput($event, item.key)"
            :multiple="item.multiple !== undefined ? item.multiple : false"
            :options="item.options"
            :disabled="item.disabled"
            :clearable="item.clearable !== undefined ? item.clearable : true"
            :getOptions="item.getOptions"
          >
          </my-check-box>
          <cbb-select
            v-else-if="item.type === 'cbb-select'"
            :style="item.style"
            :value="value[item.key]"
            @input="onInput($event, item.key)"
            :code="item.cbbCode"
            :multiple="item.multiple !== undefined ? item.multiple : false"
            :clearable="item.clearable !== undefined ? item.clearable : true"
            :disabled="item.disabled"
            :filterable="item.filterable"
          />
          <city-picker
            v-else-if="item.type === 'address'"
            :style="item.style"
            :value="value[item.key]"
            @input="onInput($event, item.key)"
            :multiple="true"
          />
          <el-cascader
            v-else-if="item.type === 'cascader'"
            :style="item.style"
            size="mini"
            :value="value[item.key]"
            @input="onInput($event, item.key)"
            :clearable="item.clearable !== undefined ? item.clearable : true"
            :options="item.options"
            :collapse-tags="item.collapseTags"
            :props="item.props || { expandTrigger: 'hover' }"
          >
          </el-cascader>
          <el-radio-group
            v-else-if="item.type === 'radio'"
            :style="item.style"
            :value="value[item.key]"
            @input="onInput($event, item.key)"
          >
            <el-radio
              :key="radio.value"
              :label="radio.value"
              v-for="radio of item.options"
              >{{
                (radio.getLabel && radio.getLabel()) || radio.label
              }}</el-radio
            >
          </el-radio-group>
          <el-date-picker
            v-else-if="item.type === 'date'"
            :style="item.style"
            :value="value[item.key]"
            @input="onInput($event, item.key)"
            :type="item.dateType || 'date'"
            :clearable="item.clearable !== undefined ? item.clearable : true"
            :range-separator="item.separator || '-'"
            :placeholder="item.placeholder || $t('startTime')"
            :value-format="item.valueFormat || 'dd/MM/yyyy'"
            :format="item.format || 'dd/MM/yyyy'"
            :picker-options="item.datePickerOption"
            :disabled="item.disabled"
          >
          </el-date-picker>
          <div v-else-if="item.type === 'date-range'" class="date-range">
            <range-date-picker
              key="start"
              :value="value[item.startDateKey]"
              @input="onInput($event, item.startDateKey)"
              :endTime="value[item.endDateKey]"
              :clearable="item.clearable !== undefined ? item.clearable : true"
              :placeholder="$t('startTime')"
              :type="item.datetime ? 'datetime' : 'date'"
              :value-format="
                item.datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'
              "
              default-time="00:00:00"
              :format="item.datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
              style="min-width: 100px"
              :intervalDays="item.intervalDays || 0"
              :disabled="item.disabled"
            >
            </range-date-picker>
            <span>-</span>
            <range-date-picker
              key="end"
              :value="value[item.endDateKey]"
              @input="onInput($event, item.endDateKey)"
              :startTime="value[item.startDateKey]"
              :clearable="item.clearable !== undefined ? item.clearable : true"
              :placeholder="$t('endTime')"
              :type="item.datetime ? 'datetime' : 'date'"
              :value-format="
                item.datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'
              "
              :format="item.datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
              default-time="23:59:59"
              style="min-width: 100px"
              :intervalDays="item.intervalDays || 0"
              :disabled="item.disabled"
            >
            </range-date-picker>
          </div>
          <!-- <el-date-picker
          v-else-if="item.type === 'date-range'"
          :style="item.style"
          :value="[
            value[item.startDateKey] || '',
            value[item.endDateKey] || ''
          ]"
          @input="onDateRangInput($event, item.startDateKey, item.endDateKey)"
          :type="item.datetime ? 'datetimerange' : 'daterange'"
          :clearable="item.clearable !== undefined ? item.clearable : true"
          :format="item.datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
          :value-format="item.datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
          :default-time="['00:00:00', '23:59:59']"
          unlink-panels
        /> -->
          <div
            v-else-if="item.type === 'number-range'"
            class="number-range"
            :style="item.style"
          >
            <number-input
              :value="value[item.startKey]"
              @input="onInput($event, item.startKey)"
              :placeholder="$t('PleaseInput', { value: '' })"
            />
            <span>-</span>
            <number-input
              :value="value[item.endKey]"
              @input="onInput($event, item.endKey)"
              :placeholder="$t('PleaseInput', { value: '' })"
            />
          </div>
          <div v-else-if="item.type === 'idCardInput'">
            <id-card-input
              :value="value[item.key]"
              @input="onInput($event, item.key)"
              :nation="item.nation || 'MM'"
              :idCardType="item.idNbrTp || '01'"
            ></id-card-input>
          </div>
          <div v-else-if="item.type === 'tellerStaff'">
            <teller-staff-select
              :value="value[item.key]"
              @input="onInput($event, item.key)"
              :clearable="item.clearable"
              :disabled="item.disabled"
            ></teller-staff-select>
          </div>
          <div v-else-if="item.type === 'allStaff'">
            <operation-and-teller-staff-select
              :value="value[item.key]"
              @input="onInput($event, item.key)"
              :clearable="item.clearable"
              :disabled="item.disabled"
            ></operation-and-teller-staff-select>
          </div>
          <div v-else-if="item.type === 'placeholder'"></div>
          <el-input
            v-else-if="item.type === 'customer'"
            :style="item.style"
            :value="
              value[item.nameKey]
                ? `${value[item.nameKey]} (${value[item.key]})`
                : value[item.key]
            "
            :disabled="true"
            :placeholder="
              item.placeholder ||
              (item.getLabel && item.getLabel()) ||
              item.label
            "
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="extraClick(item)"
            ></el-button>
          </el-input>
          <el-input
            v-else
            :style="item.style"
            :value="value[item.key]"
            :disabled="item.disabled"
            @input="onInput($event, item.key)"
            :placeholder="
              item.placeholder ||
              (item.getLabel && item.getLabel()) ||
              item.label
            "
          >
            <el-button
              v-if="item.extra"
              slot="append"
              :icon="item.icon || 'el-icon-search'"
              @click="extraClick(item)"
            ></el-button>
          </el-input>
        </div>
      </template>
    </template>
    <slot name="default"></slot>
    <div class="search-box-item">
      <p class="search-box-label">&nbsp;</p>
      <div class="search-box-buttons">
        <el-button icon="el-icon-search" type="primary" @click="searchClick">
          {{ $t('search') }}
        </el-button>
        <el-button icon="el-icon-refresh-left" @click="resetFrom">
          {{ $t('reset') }}
        </el-button>
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Cascader, Badge } from 'element-ui'
import TellerStaffSelect from '@/projects/teller/components/TellerStaffSelect.vue'
import OperationAndTellerStaffSelect from '@/projects/teller/components/OperationAndTellerStaffSelect.vue'
export default {
  name: 'search-box',
  components: {
    [Cascader.name]: Cascader,
    [Badge.name]: Badge,
    TellerStaffSelect,
    OperationAndTellerStaffSelect
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    },
    config: {
      type: Array,
      default: function () {
        return []
      }
    },
    buttonColSpan: {
      type: Number
    },
    buttonColStyle: {
      type: [Object, String]
    }
  },
  data() {
    let defaultValues = { ...this.value }
    return {
      defaultValues: defaultValues
    }
  },
  methods: {
    searchClick() {
      this.$emit('search', this.value)
    },
    onInput(value, key) {
      let valueObj = { ...this.value, [key]: value }
      this.$emit('input', valueObj, key, value)
    },
    onDateRangInput(value, startDateKey, endDateKey) {
      let startValue = value ? value[0] : ''
      let endValue = value ? value[1] : ''
      let valueObj = {
        ...this.value,
        [startDateKey]: startValue,
        [endDateKey]: endValue
      }
      this.$emit('input', valueObj, [startDateKey, endDateKey])
    },
    onTabInput(value, key) {
      let valueObj = { ...this.value, [key]: value }
      this.$emit('input', valueObj, key, value)
      this.$emit('search', this.value)
    },
    resetFrom() {
      let valueObj = { ...this.defaultValues }
      this.$emit('input', valueObj)
      this.$emit('reset')
    },
    extraClick(item) {
      this.$emit('extra-click', item.key)
    }
  }
}
</script>
<style lang="scss">
.search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px 24px 0;
  border-radius: 4px;
  background: #f5f5f5;
  .tab-select {
    width: 100%;
    margin-bottom: 20px;
    .el-badge {
      margin-bottom: 10px;
      margin-right: 40px;
    }
  }
  .search-box-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 12px;
    font-size: 0px;
    .search-box-label {
      position: relative;
      width: 100%;
      height: 16px;
      margin-bottom: 6px;
      text-indent: 2px;
      line-height: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      color: #333;
      &.required {
        padding-left: 16px;
        &::before {
          position: absolute;
          left: 0;
          top: 2px;
          content: '*';
          color: #f56c6c;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
    $--input-width: 180px;
    > .number-range,
    > .date-range,
    > .el-cascader,
    > .el-input,
    > .el-select,
    > .el-date-editor {
      width: $--input-width;
    }
    > .number-range,
    > .date-range,
    > .el-checkbox-group,
    > .el-radio-group,
    > .el-date-editor.el-date-editor--datetimerange {
      width: $--input-width * 2 + 10px;
    }
    .el-checkbox-group {
      line-height: 28px;
    }
    .el-input.is-disabled .el-input__inner {
      color: #333;
    }
    .el-radio-group {
      margin-top: 5px;
    }
  }
  .number-range,
  .date-range {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      margin: 0 4px 2px;
      color: #333;
      font-size: 12px;
    }
    .el-input {
      flex: 1;
    }
  }
  .el-input-group__append {
    background: #f5f5f5;
    color: #000;
    padding: 0 12px;
    &:hover {
      color: $--color-primary;
      background: #f5f5f5;
    }
  }
  .search-box-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    // margin-right: 40px;
    min-width: 40px;
    color: #797979;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    color: #101010;
    &.active {
      color: #4d03d4;
      border-bottom-color: #4d03d4;
    }
    .badge {
      display: flex;
      position: absolute;
      top: 0px;
      right: 0px;
    }
    .search-box-tab-sub {
      color: #797979;
      &.active {
        color: #4d03d4;
      }
    }
  }

  .search-box-buttons {
    display: flex;
    flex-direction: row;
  }
}
</style>
