import { render, staticRenderFns } from "./SearchBox.vue?vue&type=template&id=2bf08146&"
import script from "./SearchBox.vue?vue&type=script&lang=js&"
export * from "./SearchBox.vue?vue&type=script&lang=js&"
import style0 from "./SearchBox.vue?vue&type=style&index=0&id=2bf08146&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/template/components/SearchBox.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fping%2F.jenkins%2Fworkspace%2FJL_UAT_OPERATION%2Fsrc%2Fcomponents%2FSearchBox.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports