import '@/i18n/template/constant/index.js'
import i18n from '@/i18n'
// 订单状态 01:待付款, 02:已付款, 03:付款失败, 04:撤销中, 05:撤销成功, 06:撤销失败, 07:退款中, 08:退款成功, 09:退款失败, 10:已关闭
export const OrderStatus = {
  UNPAID: '01',
  PAID: '02',
  PAID_FAILED: '03',
  PartialRefundSuccess: '04',
  Paying: '05',
  REFUND_WAITING: '07',
  REFUND_SUCCESS: '08',
  REFUND_FAILED: '09',
  REFUND_ALL: '04,07,08,09',
  CLOSED: '10',
  PAYING: '20'
}

export const OrderStatusOptions = [
  {
    value: OrderStatus.UNPAID,
    getLabel: () => i18n.t('merchant.unpaid'),
    color: '#E6A23C'
  },
  { value: OrderStatus.PAID, getLabel: () => i18n.t('Paid'), color: '#67C23A' },
  {
    value: OrderStatus.PAID_FAILED,
    getLabel: () => i18n.t('PAYFAILED'),
    color: '#F56C6C'
  },
  {
    value: OrderStatus.Paying,
    getLabel: () => i18n.t('Paying'),
    color: '#E6A23C'
  },
  {
    value: OrderStatus.PartialRefundSuccess,
    getLabel: () => 'Partial Refund Success',
    color: '#67C23A'
  },
  {
    value: OrderStatus.REFUND_WAITING,
    getLabel: () => i18n.t('REFUNDING'),
    color: '#E6A23C'
  },
  {
    value: OrderStatus.REFUND_SUCCESS,
    getLabel: () => i18n.t('REFUNDED'),
    color: '#67C23A'
  },
  {
    value: OrderStatus.REFUND_FAILED,
    getLabel: () => i18n.t('REFUNDEDREJECT'),
    color: '#F56C6C'
  },
  {
    value: OrderStatus.CLOSED,
    getLabel: () => i18n.t('chat.Close'),
    color: '#909399'
  }
]
// 支付状态 paySts：支付中01，支付失败02，支付成功03，退款中11，退款失败12，退款成功13
export const PayStatus = {
  PAYING: '01',
  PAID_FAILED: '02',
  PAID: '03',
  REFUND_WAITING: '11',
  REFUND_FAILED: '12',
  REFUND_SUCCESS: '13'
}
// 支付渠道
export const PayChannel = {
  CASH: '-1', // 点餐现金支付
  TRUSTY: '00',
  WECHATPAY: '01',
  ALIPAY: '02',
  UNIONPAY: '03',
  MPU: '10',
  MABPAY: '11',
  CBBANK: '12',
  UABPAY: '14'
}

export const PayChannelItemMap = {
  [PayChannel.TRUSTY]: {
    title: 'Trusty Pay',
    id: PayChannel.TRUSTY,
    str: 'trusty'
  },
  [PayChannel.CBBANK]: {
    title: 'CB Pay',
    id: PayChannel.CBBANK,
    str: 'cbbank'
  },
  [PayChannel.MPU]: {
    title: 'MPU Card Payment',
    id: PayChannel.MPU,
    str: 'mpupay'
  },
  [PayChannel.UABPAY]: {
    title: 'uabpay',
    id: PayChannel.UABPAY,
    str: 'uabpay'
  },

  [PayChannel.MABPAY]: {
    title: 'Visa/Master',
    id: PayChannel.MPU,
    str: 'mabpay'
  },

  [PayChannel.WECHATPAY]: {
    title: 'Wechat Pay',
    id: PayChannel.WECHATPAY,
    str: 'wechat'
  },
  [PayChannel.ALIPAY]: {
    title: 'Ali Pay',
    id: PayChannel.ALIPAY,
    str: 'alipay'
  },
  [PayChannel.UNIONPAY]: {
    title: 'Union Pay',
    id: PayChannel.UNIONPAY,
    str: 'union'
  },
  [PayChannel.CASH]: {
    title: 'CASH PAY',
    id: PayChannel.CASH,
    str: 'cash'
  }
}
// 支付渠道
export const PayChannelOptions = [
  {
    value: PayChannel.TRUSTY,
    label: PayChannelItemMap[PayChannel.TRUSTY].title
  },
  {
    value: PayChannel.CBBANK,
    label: PayChannelItemMap[PayChannel.CBBANK].title
  },
  { value: PayChannel.MPU, label: PayChannelItemMap[PayChannel.MPU].title },
  {
    value: PayChannel.UABPAY,
    label: PayChannelItemMap[PayChannel.UABPAY].title
  }
  // {
  //   value: PayChannel.MABPAY,
  //   label: PayChannelItemMap[PayChannel.MABPAY].title
  // }
]
export const GateWayPayChannel = {
  // Ayapay: 'ayapay',
  MPU: 'mpupay',
  CBBANK: 'cbbank',
  UABPAY: 'uabpay',
  TRUSTY: 'trusty',
  MABPAY: 'mabpay'
}
export const GateWayPayChannelOptions = [
  {
    value: GateWayPayChannel.MPU,
    label: PayChannelItemMap[PayChannel.MPU].title
  },
  {
    value: GateWayPayChannel.CBBANK,
    label: PayChannelItemMap[PayChannel.CBBANK].title
  },
  {
    value: GateWayPayChannel.UABPAY,
    label: PayChannelItemMap[PayChannel.UABPAY].title
  },
  {
    value: GateWayPayChannel.TRUSTY,
    label: PayChannelItemMap[PayChannel.TRUSTY].title
  },
  {
    value: GateWayPayChannel.MABPAY,
    label: PayChannelItemMap[PayChannel.MABPAY].title
  }
]

export const GateWayPayChannelItemMap = {
  [GateWayPayChannel.MPU]: {
    value: GateWayPayChannel.MPU,
    label: PayChannelItemMap[PayChannel.MPU].title,
    color: '#1c84c6'
  },
  [GateWayPayChannel.CBBANK]: {
    value: GateWayPayChannel.CBBANK,
    label: PayChannelItemMap[PayChannel.CBBANK].title,
    color: '#f8ac59'
  },
  [GateWayPayChannel.UABPAY]: {
    value: GateWayPayChannel.UABPAY,
    label: PayChannelItemMap[PayChannel.UABPAY].title,
    color: '#68111a'
  },
  [GateWayPayChannel.TRUSTY]: {
    value: GateWayPayChannel.TRUSTY,
    label: PayChannelItemMap[PayChannel.TRUSTY].title,
    color: '#ed5565'
  },
  [GateWayPayChannel.MABPAY]: {
    value: GateWayPayChannel.MABPAY,
    label: PayChannelItemMap[PayChannel.MABPAY].title,
    color: '#23c6c8'
  }
}

// 订单退款状态 01申请中； 02商户同意； 03 商户拒绝
export const OrderRefundStatus = {
  APPLYING: '01',
  AGREE: '02',
  REJECT: '03'
}
// 订单类型
export const OrderType = {
  // 第三方跳转到APP使用trusty支付
  TrustyPayForThirdparty: '00',
  BALANCE: '01',
  MERCHANT: '02',
  PRODUCT: '03',
  BILL: '04',
  HOUSING: '09',
  MOBILE_TOPUP: '10',
  FIX_DEPOSIT: '30',
  GIFT_CARD: '12',
  ACTIVITY_PRODUCT: '21', // 福利活动商品订单
  MERCHANT_RESTAURANT: '07', //商户点餐
  Emeral_Loan: '08',
  Buy_Lottery: '13',
  GOLD_BUY: '15',
  GOLD_SELL: '16',
  Flymya: '17'
}

// 证件号类型
export const IDCardTP = [
  {
    value: '01',
    label: 'Social security number'
  },
  {
    value: '03',
    label: 'Driver license'
  },
  {
    value: '04',
    label: 'Passport number'
  },
  {
    value: '05',
    label: 'Foreigner registration number'
  },
  {
    value: '02',
    label: 'Business registration'
  },
  {
    value: '06',
    label: 'Coporation registration number'
  },
  {
    value: '07',
    label: 'Association registration number'
  },
  {
    value: '08',
    label: 'Tax number'
  },
  {
    value: '09',
    label: 'Unionpay Identification'
  },
  {
    value: '11',
    label: 'Virtual User Number'
  }
]

// 商户证件号类型
export const MerchantIDCardTP = [
  {
    label: 'NRC',
    value: '01'
  },
  {
    label: 'GRN',
    value: '12'
  },
  {
    label: 'RCDC',
    value: '13'
  },
  {
    label: 'DICA',
    value: '14'
  }
]

// 币种排序
export const CONST_FLAG_SORT_NBR = {
  USD: '2',
  CNY: '6',
  MMK: '1',
  THB: '5',
  EUR: '3',
  SGD: '4'
}

export const QRType = {
  MRT_RESTAURANT: '07', // 商家点餐
  MRT_PAYMENT: '05', // 扫商家二维码的支付
  CUST_ACCOUNT: '10', // 这个是客户的账户信息
  CASH_OUT: '20', // 商户Cash Out  客户 扫 商户
  EVENT_TRANSFER: '15',
  EVENT_WELCOME: '16',
  EVENT_ADVERTISE: '17',
  GUARANTEE_PAYMENT: '30', // 担保支付
  ADD_FRIEND: '100', // 添加好友
  SCAN_LOGIN: '201', // 商户扫码登录
  PROMOTION_OFFLINE: '998' // 线下促销二维码
}

export const PHONEAREA = [
  {
    natyIsoNatCd: 'MM',
    code: 95,
    nrcType: '01',
    zh: '缅甸',
    en: 'Myanmar',
    mm: 'မြန်မာနိုင်ငံ'
  },
  {
    natyIsoNatCd: 'CN',
    code: 86,
    nrcType: '04',
    zh: '中国',
    en: 'China',
    mm: 'တရုတ်နိုင်ငံ'
  },
  {
    code: 886,
    nrcType: '04',
    zh: '中国台湾',
    en: 'China-Taiwan',
    mm: 'ထိုင်ဝမ်'
  },
  {
    code: 852,
    nrcType: '04',
    zh: '中国香港',
    en: 'Hong Kong',
    mm: 'ဟောင်ကောင်'
  },
  {
    code: 66,
    nrcType: '04',
    zh: '泰国',
    en: 'Thailand',
    mm: 'ထိုင်းနိုင်ငံ'
  },
  {
    natyIsoNatCd: 'SG',
    code: 65,
    nrcType: '04',
    zh: '新加坡',
    en: 'Singapore',
    mm: 'စင်္ကာပူ'
  },
  {
    code: 91,
    nrcType: '04',
    zh: '印度',
    en: 'India',
    mm: 'အိန္ဒိယ'
  },
  {
    code: 1,
    nrcType: '04',
    zh: '美国',
    en: 'United States',
    mm: 'ယူနိုက်တက်စတိတ်'
  },
  {
    code: 63,
    nrcType: '04',
    zh: '菲律宾',
    en: 'Philippines',
    mm: 'ဖိလစ်ပိုင်'
  },
  {
    code: 60,
    nrcType: '04',
    zh: '马来西亚',
    en: 'Malaysia',
    mm: 'မလေးရှား'
  },
  {
    code: 61,
    nrcType: '04',
    zh: '澳大利亚',
    en: 'Australia',
    mm: 'သြစတြေးလျ'
  },
  {
    code: 81,
    nrcType: '04',
    zh: '日本',
    en: 'Japan',
    mm: 'ဂျပန်'
  },
  {
    code: 82,
    nrcType: '04',
    zh: '韩国',
    en: 'Korea, South',
    mm: 'ကိုရီးယား'
  },
  {
    code: 44,
    nrcType: '04',
    zh: '英国',
    en: 'United Kingdom',
    mm: 'ယူနိုက်တက်ကင်းဒမ်း'
  }
]
export const RegisterCountry = [
  {
    value: 'MM',
    zh: '缅甸',
    en: 'Myanmar',
    mm: 'မြန်မာနိုင်ငံ'
  },
  {
    value: 'CN',
    zh: '中国',
    en: 'China',
    mm: 'တရုတ်နိုင်ငံ'
  },
  {
    value: 'SG',
    zh: '新加坡',
    en: 'Singapore',
    mm: 'စင်္ကာပူ'
  }
]
// 证件号类型
export const ResiterIDCardTP = [
  {
    value: '01',
    label: 'Social security number'
  },
  {
    value: '04',
    label: 'Passport number'
  },
  {
    value: '03',
    label: 'Driver license'
  },
  {
    value: '05',
    label: 'Foreigner registration number'
  }
]

export const AccountBizType = {
  SavingAccount: '01',
  LoanAccount: '02'
}
export const PdCdType = {
  Custodial_Account: '01011000100000000031',
  MiningShareLoan: '01021000300000000014',
  StaffLoanFlood: '01021000300000000015',
  UnpaidShareLoan: '01021000300000000016',
  TrustyFamilyShareLoan: '01021000300000000017',
  RoyalSkyShareLoan: '01021000300000000018',
  StaffLoanShareGuarantee2: '01021000300000000019',
  FDPBShareLoan: '01021000300000000020',
  TimberShareLoan: '01021000300000000021',
  StaffLoanHousing: '01021000300000000022',
  StaffLoanShareGuarantee: '01021000300000000024',
  TimberGroupShareLoan: '01021000300000000013',
  BranchSavingOverdraft: '01021000300000000004',
  HotelShareLoan: '01021000300000000001',
  PersonalLoan: '01021000300000000002',
  HirePurchase: '01021000100000000001',
  PayDayLoan: '01021000300000000025',
  FIX_DEPOSIT: '01011000100000000027',
  SAVING_ACCOUNT: '01011000100000000007',
  SAVING_ACCOUNT_WITH_CREDIT: '01011000100000000026',
  WAIVING_TRANSFER: '01050100100000000001',
  WAIVING_CASH_IN: '01050100100000000002',
  WAIVING_CASH_OUT: '01050100100000000003',
  BANK_TRANSFER_OUT: '01050100100000000004',
  BANK_TRANSFER_IN: '01050100100000000014',
  WAIVING_MOBILE_TOP: '01050300100000000001',
  BRANCH_SAVING_CASHINOUT: '01011000100000000025',
  MOBILE_PAYMENT_MERCHANT: '01050500100000000001',
  SELF_CHANNEL_PAYMENT: '01050500200000000001',
  FX_SELL: '01050400100000000002',
  FX_BUY: '01050400100000000003',
  MERCHANT_COUPON: '01320200200000000001',
  EVENT_TRANSFER: '01050100100000000006',
  EVENT_WELCOME: '01050100100000000008',
  EVENT_ADVERTISE: '01050100100000000009',

  Emeral_Saving: '01011000100000000028',
  Emeral_Loan: '01021000100000000002',
  BRANCH_CALL: '01011000100000000016',
  BRANCH_SAVING: '01011000100000000014',
  Bank_Loan_Account: '01011000100000000018',

  Call_Account: '01011000100000000015',
  Joint_Saving_Account: '01011000100000000029',
  Joint_Call_Account: '01011000100000000030'
}
export const TransactionType = {
  AssetsDeposit: '7038', //	定期存款
  AssetsWithdrawal: '7039', //	定期取款
  AssetsInterest_Deposit: '7021', // 	定期结息收入
  Cash_In: '6017', // 现金存入
  Balance_Topup: '7007', // 余额充值
  Cash_Out: '6018', // 现金提取
  Teller_Cash_In: '6009', // Teller 现金存入
  Teller_Cash_In_1: '1001', // Teller 现金存入
  Teller_Cash_Out: '6010', // Teller 现金提取
  Trusty_Coin_Buy: '7004', // Trusty币购买  账户扣款
  Trusty_Coin_Sell: '7003', // Trusty币卖出  账户入账
  Transfer_BANK_WITHDRAWAL: '7008', // 银行转出
  Transfer_BANK_DEPOSIT: '-7008', // 银行转入
  Transfer_BANK_FEE: '7022', // 银行手续费
  Transfer_FX_WITHDRAWAL: '7010', // 外币转出
  Transfer_FX_DEPOSIT: '7009', // 外币转入
  Transfer_MM_WITHDRAWAL: '6006', // 缅币转出
  Transfer_MM_DEPOSIT: '6005', // 缅币转入
  Transfer_RqeustMoney_DEPOSIT: '7011', // Request Money 转入
  Transfer_RqeustMoney_WITHDRAWAL: '7012', // Request Money 转出
  Transfer_Fast_DEPOSIT: '7013', // Fast Transfer From merchant
  Transfer_Fast_WITHDRAWAL: '7014', // merchant Fast Transfer to customer
  Transfer_OTHER_DEPOSITL: '1002', // Other Transfer deposit
  Transfer_OTHER_WITHDRAWAL: '2002', // Other Transfer WITHDRAWAL
  RedPacket_WITHDRAWAL: '7016', // 发送红包
  RedPacket_DEPOSIT: '7015', // 接收红包
  EVENT_RedPacket_WITHDRAWAL: '7020', // 活动发送红包
  EVENT_RedPacket_DEPOSIT: '7019', // 活动接收红包
  Loan_Debit: '6015', // 贷款借入
  Loan_Repayment_1: '6013', // 贷款偿还 本金 利息
  Loan_Repayment_2: '6014', // 贷款偿还 本金 利息
  Loan_Repayment_3: '6016', // 全部贷款一次性偿还 本金 利息
  Fx_BUY: '6003', //  外汇买入
  Fx_SELL: '6004', //  外汇卖出
  Payment_MOBILE_TOPUP: '6008', //   Mobile Topup
  Payment_GIFTCARD: '7017', //   GiftCard
  Payment_MERCHANT_Buy: '6002', //   customer buy from merchant
  Payment_MERCHANT_Sell: '6001', //   Merchant sell
  House_Rental_Sell_Cash: '7051', // 房租支付商户现金收款 现在算其他
  Payment_Refund: '6011', //  支付退款
  Payment_Refund_Merchant: '6012', //  支付退款
  Lottery_Buy: '7041', //  购买彩票账户付款
  Lottery_Sell: '7042', //  彩票商户收款
  Flymya_Buy: '7052', //  机票购买
  Flymya_Sell: '7053', //  机票卖出
  Billing: '7030', // Billing
  SETTLEMENT: '7018', //  系统结息
  SETTLEMENT_FIXED: '7021', //  定期系统结息

  EmeraldBilling_WITHDRAWAL: '7031', // 放贷商户扣款
  EmeraldBilling_DEPOSIT: '7032', // 放贷客户存款
  EmeraldBilling_Rrepayment_WITHDRAWAL: '7033', //还贷客户扣款
  EmeraldBilling_Rrepayment_DEPOSIT: '7034', // 还贷商户存款
  EmeraldBilling_Rrepayment_WITHDRAWAL_New: '7037', // 新Emerald账单还贷客户扣款

  // 客户支付7043商户收款7044
  TrustyPayForThirdparty_Buy: '7043',
  TrustyPayForThirdparty_Sell: '7044',
  GOLD_BUY: '7045', // 买黄金
  GOLD_SELL: '7046', // 卖黄金
  GOLD_EXCHANGE: '7049', // 兑换实物黄金
  Husing_Rental: '7040', // 房租账单

  ALL_Expenses: '2', //所有支出
  ALL_Revenue: '1', // 所有收入
  OTHER: 'Y' //  其他
}
export const LiveCategory = {
  HOT: '',
  HOME: '01',
  DIGITAL: '02',
  DELICACY: '03',
  JEWELRY: '04',
  TRAVEL: '05',
  CAR: '06',
  MUSIC: '07',
  DANCE: '08',
  CHAT_INTERACTION: '09',
  OUTDOOR: '10',
  CULTURAL_TALENT: '11',
  FOOD: '12',
  KNOWLEDGE_TEACHING: '13',
  OTHER: '14',
  SELLING_GOODS: '15'
}

export const NeverExpiredDate = '99991231'
// 99991231235959
export const NeverExpiredTime = NeverExpiredDate + '235959'
// for biz request
export const NeverExpiredDateObject = '9999-12-31'
// 9999-12-31 23:59:59
export const NeverExpiredTime_DateObjet = NeverExpiredDateObject + ' 23:59:59'

export const ToDoAplyTp = {
  OpenAccount: '01',
  AddMember: '02',
  RemoveMember: '03',
  Transfer: '04',
  Payment: '05',
  BankTransfer: '06',
  RequestMoney: '07',
  CashOut: '08',
  RedPacket: '09',
  FX_Buy: '10',
  Fixed: '11',
  Repayment_Part: '12',
  Repayment_All: '13',
  TrustyCoinsExchangeBuy: '14'
}
export const JointAccountAplyTypeMap = {
  [ToDoAplyTp.OpenAccount]: {
    title: i18n.t('OpenAccount'),
    id: ToDoAplyTp.OpenAccount
  },
  [ToDoAplyTp.AddMember]: {
    title: i18n.t('AddMember'),
    id: ToDoAplyTp.AddMember
  },
  [ToDoAplyTp.RemoveMember]: {
    title: i18n.t('RemoveMember'),
    id: ToDoAplyTp.RemoveMember
  },
  [ToDoAplyTp.Transfer]: {
    title: i18n.t('Transfer'),
    id: ToDoAplyTp.Transfer
  },
  [ToDoAplyTp.Payment]: {
    title: i18n.t('Payment'),
    id: ToDoAplyTp.Payment
  },
  [ToDoAplyTp.BankTransfer]: {
    title: i18n.t('BankTransfer'),
    id: ToDoAplyTp.BankTransfer
  },
  [ToDoAplyTp.RequestMoney]: {
    title: i18n.t('chat.requestMoney'),
    id: ToDoAplyTp.RequestMoney
  },
  [ToDoAplyTp.CashOut]: {
    title: i18n.t('CashOut'),
    id: ToDoAplyTp.CashOut
  },
  [ToDoAplyTp.RedPacket]: {
    title: i18n.t('RedPacket.title'),
    id: ToDoAplyTp.RedPacket
  },
  [ToDoAplyTp.FX_Buy]: {
    title: i18n.t('FxExchangeBuy'),
    id: ToDoAplyTp.FX_Buy
  },
  [ToDoAplyTp.Fixed]: {
    title: i18n.t('accountStr.fixed'),
    id: ToDoAplyTp.Fixed
  },
  [ToDoAplyTp.Repayment_Part]: {
    title: i18n.t('Repayment'),
    id: ToDoAplyTp.Repayment_Part
  },
  [ToDoAplyTp.Repayment_All]: {
    title: i18n.t('Repayment'),
    id: ToDoAplyTp.Repayment_All
  },
  [ToDoAplyTp.TrustyCoinsExchangeBuy]: {
    title: i18n.t('TrustyCoinsExchangeBuy'),
    id: ToDoAplyTp.TrustyCoinsExchangeBuy
  }
}
export const JointAccountAplyTypeOptions = [
  // {
  //   value: ToDoAplyTp.OpenAccount,
  //   label: JointAccountAplyTypeMap[ToDoAplyTp.OpenAccount].title
  // },
  // {
  //   value: ToDoAplyTp.AddMember,
  //   label: JointAccountAplyTypeMap[ToDoAplyTp.AddMember].title
  // },
  // {
  //   value: ToDoAplyTp.RemoveMember,
  //   label: JointAccountAplyTypeMap[ToDoAplyTp.RemoveMember].title
  // },
  {
    value: ToDoAplyTp.Transfer,
    label: JointAccountAplyTypeMap[ToDoAplyTp.Transfer].title
  },
  {
    value: ToDoAplyTp.Payment,
    label: JointAccountAplyTypeMap[ToDoAplyTp.Payment].title
  },
  {
    value: ToDoAplyTp.BankTransfer,
    label: JointAccountAplyTypeMap[ToDoAplyTp.BankTransfer].title
  },
  {
    value: ToDoAplyTp.RequestMoney,
    label: JointAccountAplyTypeMap[ToDoAplyTp.RequestMoney].title
  },
  {
    value: ToDoAplyTp.CashOut,
    label: JointAccountAplyTypeMap[ToDoAplyTp.CashOut].title
  },
  {
    value: ToDoAplyTp.RedPacket,
    label: JointAccountAplyTypeMap[ToDoAplyTp.RedPacket].title
  },
  {
    value: ToDoAplyTp.FX_Buy,
    label: JointAccountAplyTypeMap[ToDoAplyTp.FX_Buy].title
  },
  {
    value: ToDoAplyTp.Fixed,
    label: JointAccountAplyTypeMap[ToDoAplyTp.Fixed].title
  },
  {
    value: ToDoAplyTp.Repayment_Part,
    label: JointAccountAplyTypeMap[ToDoAplyTp.Repayment_Part].title
  },
  {
    value: ToDoAplyTp.TrustyCoinsExchangeBuy,
    label: JointAccountAplyTypeMap[ToDoAplyTp.TrustyCoinsExchangeBuy].title
  }
]

export const MerchantScopeOfOperationType = {
  GeneralMerchants: '01',
  CashCounter: '02',
  AgencyManagement: '04',
  Restaurant: '05',
  DUHD_Rental: '06',
  DUHD_Rental_NPT: '07',
  DUHD_Rental_YGN: '08'
}

export const MerchantScopeOfOperationTypeOptions = [
  {
    value: MerchantScopeOfOperationType.GeneralMerchants,
    getLabel: () => i18n.t('GeneralMerchants')
  },
  {
    value: MerchantScopeOfOperationType.CashCounter,
    getLabel: () => i18n.t('CashCounter')
  },
  {
    value: MerchantScopeOfOperationType.AgencyManagement,
    getLabel: () => i18n.t('agency_management')
  },
  {
    value: MerchantScopeOfOperationType.Restaurant,
    getLabel: () => i18n.t('Restaurant')
  }
]
export const MerchantScopeOfOperationTypeOptionsForShowText = [
  ...MerchantScopeOfOperationTypeOptions,
  {
    value: MerchantScopeOfOperationType.DUHD_Rental,
    getLabel: () => 'DUHD Rental',
    disabled: true
  },
  {
    value: MerchantScopeOfOperationType.DUHD_Rental_NPT,
    getLabel: () => 'DUHD Rental (NPT)',
    disabled: true
  },
  {
    value: MerchantScopeOfOperationType.DUHD_Rental_YGN,
    getLabel: () => 'DUHD Rental (YGN)',
    disabled: true
  }
]
export const ImportBizType = {
  Meter: '1'
}

export const MobileTopupCarrier = {
  MPT: '01',
  Telenor: '02',
  Ooredoo: '03',
  MEC: '04',
  MyTel: '05'
}

export const MobileTopupCarrierOptions = [
  { label: 'MPT', value: MobileTopupCarrier.MPT },
  { label: 'Telenor', value: MobileTopupCarrier.Telenor },
  { label: 'Ooredoo', value: MobileTopupCarrier.Ooredoo },
  { label: 'MEC', value: MobileTopupCarrier.MEC },
  { label: 'MyTel', value: MobileTopupCarrier.MyTel }
]
