export default [
  {
    name: 'redPacketCampaign',
    path: '/redPacketCampaignList',
    component: () =>
      import(/* webpackChunkName: "campaignList" */ './Redpacket/index.vue')
  },
  {
    name: 'redpacketCampaignDetail',
    path: '/redPacketCampaignList/redpacket_detail',
    component: () =>
      import(
        /* webpackChunkName: "campaignDetail" */ './Redpacket/RedpacketDetail.vue'
      )
  },
  {
    name: 'redPacketCampaignStatistics',
    path: '/redPacketCampaignList/statistics',
    component: () =>
      import(
        /* webpackChunkName: "campaignList" */ './Redpacket/statistics.vue'
      )
  },
  {
    name: 'campaignList',
    path: 'campaignList',
    component: () =>
      import(/* webpackChunkName: "campaignList" */ './index.vue')
  },
  {
    name: 'commodityCampaignDetail',
    path: '/campaignList/commodity_detail',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "campaignDetail" */ './Commodity/CommodityDetail.vue'
      )
  },
  {
    name: 'cashCampaignDetail',
    path: '/campaignList/cash_detail',
    component: () =>
      import(/* webpackChunkName: "campaignDetail" */ './Cash/CashDetail.vue')
  },
  {
    name: 'coupon_campaign_detail',
    path: '/campaignList/coupon_detail',
    component: () =>
      import(
        /* webpackChunkName: "campaignDetail" */ './Coupon/CouponDetail.vue'
      )
  },
  {
    name: 'campaignStatistics',
    path: '/campaignList/campaignStatistics',
    component: () =>
      import(/* webpackChunkName: "campaignStatistics" */ './NewStatistics.vue')
  },
  {
    name: 'campaign_cash_statistics',
    path: '/campaignList/campaign_cash_statistics',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "campaignStatistics" */ './CashStatistics.vue'
      )
  },
  {
    name: 'campaign_coupon_statistics',
    path: '/campaignList/campaign_coupon_statistics',
    component: () =>
      import(
        /* webpackChunkName: "campaignStatistics" */ './CouponStatistics.vue'
      )
  },
  {
    name: 'touupCampaignDetail',
    path: '/campaignList/topup_detail',
    component: () =>
      import(/* webpackChunkName: "campaignDetail" */ './Topup/TopupDetail.vue')
  },
  {
    name: 'campaign_topup_statistics',
    path: '/campaignList/campaign_topup_statistics',
    component: () =>
      import(
        /* webpackChunkName: "campaignStatistics" */ './TopupStatistics.vue'
      )
  }
]
