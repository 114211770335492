<i18n src="@/i18n/template/components/DatePickerComponent.json"></i18n>
<template>
  <span>
    <span
      style="margin-right: 10px; color: #333; font-size: 14px; font-weight: 500"
      >{{ $t('By') }}</span
    >
    <el-select v-model="localBy" @change="onTypeChange">
      <el-option :value="TimeType.Day" :label="$t('Daily')"></el-option>
      <el-option :value="TimeType.Month" :label="$t('Monthly')"></el-option>
      <el-option :value="TimeType.Quarter" :label="$t('Quarterly')"></el-option>
      <el-option :value="TimeType.Year" :label="$t('Yearly')"></el-option>
    </el-select>
    <quarter-picker
      v-if="localBy === TimeType.Quarter"
      v-model="localValue"
      @change="onDateChange"
      :max="maxDate"
      type="range"
    />
    <year-picker
      v-else-if="localBy === TimeType.Year"
      v-model="localValue"
      @change="onDateChange"
      :max="maxDate"
      type="range"
    />
    <el-date-picker
      v-else
      v-model="localValue"
      :type="datePickerType"
      @change="onDateChange"
      value-format="dd/MM/yyyy"
      :picker-options="datepickerOptions"
    ></el-date-picker>
    <!-- <div>
      <p>开始日期: {{ localValue[0] }}</p>
      <p>结束日期: {{ localValue[1] }}</p>
    </div> -->
  </span>
</template>

<script>
const TimeType = {
  Day: '1',
  Month: '2',
  Quarter: '3',
  Year: '4'
}
export default {
  props: {
    by: {
      type: String,
      required: true
    },
    startDt: {
      type: String,
      required: true
    },
    endDt: {
      type: String,
      required: true
    },
    maxDate: {
      type: Number,
      required: true
    },
    datePickerType: {
      type: String,
      required: true
    },
    datepickerOptions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      TimeType,
      localValue: [
        this.startDt, // 初始化为传入的开始日期
        this.endDt
      ],
      localBy: this.by
    }
  },
  watch: {
    by(newBy) {
      this.localBy = newBy // 更新时间类型
    }
  },
  methods: {
    onTypeChange() {
      this.$emit('update:by', this.localBy)
      this.localValue = [] // 重置为初始值
    },
    onDateChange(value) {
      if (this.validateDateRange(value)) {
        const formattedValue = this.formatValue(value)
        // this.localValue = formattedValue // Update localValue
        // 同时更新 startDt 和 endDt
        this.$emit('update:change', formattedValue)
      }
    },
    validateDateRange(value) {
      const maxDay = 31
      const [startDt, endDt] = value
      if (
        this.localBy === this.TimeType.Day &&
        this.dayjs.from(endDt).diff(this.dayjs.from(startDt), 'day') > maxDay
      ) {
        this.localValue = []
        this.$emit('update:change', ['', ''])
        this.$message.error(
          this.$t('TheSearchConditionMustBeWithin30Days', { day: maxDay })
        )
        return false
      }
      return true // Valid date range
    },
    formatValue(value) {
      // 格式化返回值为
      // 处理月份、季度和年份的日期补全
      const [startDt, endDt] = value
      if (this.localBy === this.TimeType.Month) {
        const start = startDt
        const end = this.dayjs.from(endDt).endOf('month').formatDate()
        return [start, end] // 返回格式化后的值
      } else if (this.localBy === this.TimeType.Quarter) {
        const start = this.dayjs(startDt).startOf('quarter').formatDate()
        // const end = this.dayjs(`${endDt}01`).endOf('quarter').formatDate() // 季度结束日期 dayjs endOf('quarter')不起作用
        const year = parseInt(endDt.substring(0, 4), 10) // 获取年份
        const month = parseInt(endDt.substring(4, 6), 10) // 获取月份
        const quarter = Math.ceil(month / 3) // 计算季度
        const end = this.dayjs(`${year}-${quarter * 3}-01`)
          .endOf('month')
          .format('YYYY-MM-DD') // 获取季度最后一天
        return [start, end] // 返回格式化后的值
      } else if (this.localBy === this.TimeType.Year) {
        const start = `01/01/${startDt}` // 01/01/YYYY
        const end = `31/12/${endDt}` // 31/12/YYYY
        return [start, end] // 返回格式化后的值
      }
      return value
    }
  }
}
</script>
