export default [
  {
    path: 'business_service_tracking',
    name: 'BusinessServiceTracking',
    component: () =>
      import(
        /* webpackChunkName: "BusinessServiceTracking" */ '@/projects/teller/views/TransactionMonitoring/BusinessServiceTracking.vue'
      )
  },
  {
    path: 'non_financial_business_service_tracking',
    name: 'Non-FinancialBusinessServiceTracking',
    component: () =>
      import(
        /* webpackChunkName: "Non-FinancialBusinessServiceTracking" */ '@/projects/teller/views/TransactionMonitoring/NonFinancialBusinessServiceTracking.vue'
      )
  }
]
