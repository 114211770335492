export default [
  {
    name: 'transaction_limit_manage',
    path: '/transaction_limit_manage',
    component: () =>
      import(
        /* webpackChunkName: "transaction_limit_manage" */ './TransactionLimitManage.vue'
      )
  }
]
